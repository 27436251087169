@font-face {
  font-family: 'Open SansB';
  src: url('../fonts/OpenSansBN-webfont/opensansb-webfont.eot');
  src: url('../fonts/OpenSansBN-webfont/opensansb-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSansBN-webfont/opensansb-webfont.woff') format('woff'),
    url('../fonts/OpenSansBN-webfont/opensansb-webfont.ttf') format('truetype'),
    url('../fonts/OpenSansBN-webfont/opensansb-webfont.svg#webfont') format('svg');
}

/* #map {
  width: 600px;
  height: 400px;
} */
html, body {
  margin: 0;
  height: 100%;
  font-family: 'Open SansB', Arial, sans-serif;
}

#nav-container {
  position: absolute;
  top: 0.938rem; /* 15/16 */
  right: 0.938rem; /* 15/16 */
  left: 3.125rem; /* 50/16 */
  z-index: 100;
}

#form-name-search {
  width: 25rem; /* 400/16 */
  float: left;
  margin: 0 0.938rem 0 0; /* 15/16 */
  z-index: 100;
}

.search-row {
  border-bottom: 0.063rem solid rgba(0, 0, 0, 0.1); /* 1/16 */
  margin-bottom: 0.188rem; /* 3/16 */
}

.search-classification {
  float: right;
}

.search-municipality {
  display: block;
  font-size: 0.625rem; /* 10/16 */
  color: rgba(0, 0, 0, 0.8);
}

#tools-container {
  position: absolute;
  top: 0.938rem; /* 15/16 */
  right: 0.938rem; /* 15/16 */
  z-index: 100;
}

.tools-group {
  margin-bottom: 1.25rem; /* 20/16 */
}

.tools-item {
  margin-bottom: 0.313rem; /* 5/16 */
  display: block;
}

#hp-interactions-container {
  position: absolute;
  bottom: 0.938rem; /* 15/16 */
  left: 0.938rem; /* 15/16 */
  z-index: 100;
}

.layer-icon {
  line-height: 1.3;
}

#dd-mb-layerswitch:hover+#dd-menu-layerswitch, #dd-menu-layerswitch:hover {
  display: block;
  margin-top: 0;
}

#dd-mb-layerswitch:after {
  content: none;
}

#bookmark-container {
  display: inline;
}

#dd-municipalities {
  display: inline;
}

#dd-villages {
  display: inline;
}

.scrollable-menu {
  height: auto;
  max-height: 37.5rem; /* 600/16 */
  overflow-x: hidden;
}

#detail-info-container {
  position: absolute;
  right: 0.938rem; /* 15/16 */
  bottom: 1.25rem; /* 20/16 */
  width: 21.875rem; /* 350/16 */
  z-index: 200;
  display: none;
}

.detail-info-table {
  font-size: 0.75rem; /* 12/16 */
}

.scrollable-detail-info {
  height: auto;
  max-height: 25rem; /* 400/16 */
  overflow-x: hidden;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 0.063rem 0.25rem rgba(0, 0, 0, 0.2)); /* 1/16, 4/16 */
  filter: drop-shadow(0 0.063rem 0.25rem rgba(0, 0, 0, 0.2)); /* 1/16, 4/16 */
  padding: 0.938rem; /* 15/16 */
  border-radius: 0.625rem; /* 10/16 */
  border: 0.063rem solid #cccccc; /* 1/16 */
  bottom: 0.75rem; /* 12/16 */
  left: -3.125rem; /* -50/16 */
  min-width: 25rem; /* 400/16 */
  z-index: 200;
}

.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 0.625rem; /* 10/16 */
  left: 3rem; /* 48/16 */
  margin-left: -0.625rem; /* -10/16 */
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 0.688rem; /* 11/16 */
  left: 3rem; /* 48/16 */
  margin-left: -0.688rem; /* -11/16 */
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 0.125rem; /* 2/16 */
  right: 0.5rem; /* 8/16 */
}

.ol-popup-closer:after {
  content: "✖";
}

.detail-info-closer {
  text-decoration: none;
  position: absolute;
  top: 0.125rem; /* 2/16 */
  right: 1.125rem; /* 18/16 */
}

.detail-info-closer:after {
  content: "✖";
}

.popup-title {
  font-size: 1.75rem; /* 28/16 */
}

.popup-category {
  font-size: 0.75rem; /* 12/16 */
  margin-left: 1.25rem; /* 20/16 */
}

.popup-content-container {
  margin-bottom: 0.625rem; /* 10/16 */
}

#popup-image {
  height: 9.375rem; /* 150/16 */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
